import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import SEO from '../../components/SEO/SEO';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import Divider from '../../../src/components/Divider';
import Card from '../../../src/components/Card';
import Breadcrumb from '../../../src/components/Breadcrumb';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../../src/components/LayoutWrapper';
import { SchoolPromotionsIndexQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import translations from '../../translations/en.json';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './school-promotions.module.scss';

type PageContextType = GlobalPageContext;

const SchoolPromotionsIndex: React.FC<PageProps<SchoolPromotionsIndexQuery, PageContextType>> = ({
  data,
  pageContext,
}) => {
  // hacking our way a bit here since publicationState seems to be an args on collections only in strapi v4
  // @ts-ignore
  const school = data.strapi.schools[0] as SchoolPromotionsIndexQuery['strapi']['school'] | undefined;
  const pages = get(data, 'strapi.pages', []);
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const intl = useIntl();

  if (!websiteLocale) return null;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.PageSettings?.title ?? intl.formatMessage(
          { id: 'seo.school-promotions.title', defaultMessage: translations['seo.school-promotions.title'] },
          {
            name: school?.name || '',
            siteTitle: pageContext.websiteLocale.name,
          },
        )}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.PageSettings?.metaDescription ?? intl.formatMessage(
          { id: 'seo.school-promotions.description', defaultMessage: translations['seo.school-promotions.description'] },
          { name: school?.name || '' },
        )}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        image={pages[0]?.seo?.metaImage?.url}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <Breadcrumb
          pageContext={pageContext}
          lastCrumb={intl.formatMessage({ id: 'promotionsIndexHeading', defaultMessage: 'Promotions' })}
        ></Breadcrumb>
        <div className="columns">
          <div className="column is-8">
            <h1 className="title color-a">
              <FormattedMessage id="promotionsAtText" defaultMessage="Promotions at" /> {school?.name}
            </h1>
            <p className="is-text-1 black-2">
              <FormattedMessage id="promotionsIndexDescription" />
            </p>
          </div>
        </div>

        <Divider paddingTop="75px" marginBottom="25px" />

        {!school?.promotions?.length ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noPromotions)}>
            <h4 className="title is-4">
              <FormattedMessage id="noPromotionsIndexHeading" />
            </h4>
            <p>
              <FormattedMessage id="noPromotionsIndexDescription" />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {compact(school?.promotions).map(({ documentId, title, slug }) => {
            if (!slug) return null;
            return (
              <Card
                urlText={intl.formatMessage({ id: 'promotionsCardText', defaultMessage: 'View Promotion' })}
                slugBase={pageContext.pathPrefixes.promotions}
                key={`promotion-${documentId}`}
                title={title ?? 'N/A'}
                slug={slug}
              />
            );
          })}
        </div>
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(SchoolPromotionsIndex));

export const query = graphql`
query SchoolPromotionsIndex($documentId: ID!, $locale: Strapi_I18NLocaleCode,$websiteLocaleId: ID!) {
  strapi {
    schools(filters: {documentId: {eq: $documentId}}, locale: $locale) {
      data {
        documentId
        attributes {
          name
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          promotions {
            data {
              documentId
              attributes {
                title
                slug
              }
            }
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
    pages(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "Schools"}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
   
}
`;
